import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../authentication/useAuth';
import { FullHeightComponent } from './components/FullHeightComponent';
import * as CC from './components/CommonComponents';
import { SearchBarWithSuggestions } from './components/SearchBarWithSuggestions';

import { useIngredientData } from './hooks/useIngredientData';
import { IngredientData } from './model/ingredient-model';

// Context
type LoadingContextType = {
    isLoading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
};

const LoadingContext = createContext<LoadingContextType>({
    isLoading: false,
    setLoading: () => {},
});

// Styled Components
const Sidebar = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 80px;
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    gap: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
`;

const TabButton = styled.button<{ isActive?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: ${(props) => (props.isActive ? '#4caf50' : 'transparent')};
    border: none;
    border-radius: 10px;
    color: ${(props) => (props.isActive ? '#fff' : '#333')};
    cursor: pointer;
    font-size: 12px;
    text-align: center;
    transition: background 0.3s;

    &:hover {
        background: ${(props) => (props.isActive ? '#45a049' : '#ddd')};
    }

    svg {
        width: 24px;
        height: 24px;
        margin-bottom: 5px;
    }
`;

// Icons as SVG components
const IngredientIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M12 2a10 10 0 110 20 10 10 0 010-20zm0 18a8 8 0 100-16 8 8 0 000 16z" />
    </svg>
);

const EquipmentIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M18 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zm-1 18H7V4h10v16z" />
    </svg>
);

const RecipeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zm0 16H5V5h14v14zM8 8h8v2H8zm0 4h5v2H8z" />
    </svg>
);

export function GenerateComponent() {
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [itemShown, setItemShown] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<'ingredients' | 'equipment' | 'recipes'>('ingredients');

    // Ingredients
    const { ingredients, fetchIngredientDetails } = useIngredientData(user);
    const [ingredientImage, setIngredientImage] = useState<string>('');
    const [ingredientData, setIngredientData] = useState<IngredientData | null>(null);

    const handleTabClick = (tab: 'ingredients' | 'equipment' | 'recipes') => {
        setActiveTab(tab);
    };

    const handleFetchIngredientDetails = async (ingredient: string) => {
        setIsLoading(true);
        try {
            const { image, data } = await fetchIngredientDetails(ingredient);
            setIngredientImage(URL.createObjectURL(image));
            setIngredientData(data);
            setItemShown(true);
        } catch (error) {
            console.error('Error fetching ingredient details:', error);
            setItemShown(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <LoadingContext.Provider value={{ isLoading, setLoading: setIsLoading }}>
            <CC.Page background={CC.PageBackground.Green}>
                <Sidebar>
                    <TabButton
                        isActive={activeTab === 'ingredients'}
                        onClick={() => handleTabClick('ingredients')}
                    >
                        <IngredientIcon />
                        Ingredients
                    </TabButton>
                    <TabButton
                        isActive={activeTab === 'equipment'}
                        onClick={() => handleTabClick('equipment')}
                    >
                        <EquipmentIcon />
                        Equipment
                    </TabButton>
                    <TabButton
                        isActive={activeTab === 'recipes'}
                        onClick={() => handleTabClick('recipes')}
                    >
                        <RecipeIcon />
                        Recipes
                    </TabButton>
                </Sidebar>
                <FullHeightComponent
                    isBottomVisible={itemShown}
                    maxBottomHeight={1024}
                    topChildren={
                        <CC.Center>
                            <CC.Title>Recipe Database</CC.Title>
                            <SearchBarWithSuggestions
                                color={CC.PageBackground.Green}
                                items={ingredients}
                                onSelect={handleFetchIngredientDetails}
                                goButtonVisible={!itemShown}
                                onGoClick={(ingredient: string) => {}}
                                onClear={() => setItemShown(false)}
                            />
                        </CC.Center>
                    }
                    bottomChildren={<div>Content based on {activeTab}</div>}
                />
            </CC.Page>
        </LoadingContext.Provider>
    );
}
