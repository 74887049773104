import { fetchWithAuth } from "../../authentication/useAuth";
import { User } from "firebase/auth";
import { IngredientData } from "./model/ingredient-model";

// Ingredients

export async function fetchIngredientsList(user: User | null) {
    const response = await fetchWithAuth(user, 'https://keter.cloud/api/v1/nutrificient/ingredients', { method: 'GET' });
    return response as string[];
}

export async function fetchIngredientDetails(user: User | null, ingredient: string) {
    const response = await fetchWithAuth(user, `https://keter.cloud/api/v1/nutrificient/ingredient/${ingredient}`, { method: 'GET' });
    const image = await fetch(response.image.url).then((res) => res.blob());
    const data = await fetch(response.data.url).then((res) => res.json()) as IngredientData
    return { image, data };
}

export async function generateIngredient(user: User | null, ingredient: string) {
    const response = await fetchWithAuth(user, `https://keter.cloud/api/v1/nutrificient/ingredient/new/${ingredient}`, { method: 'GET' });
    return response;
}

// Equipment

export async function fetchEquipmentList(user: User | null) {
    const response = await fetchWithAuth(user, 'https://keter.cloud/api/v1/nutrificient/equipments', { method: 'GET' });
    return response as string[];
}

export async function fetchEquipmentDetails(user: User | null, equipment: string) {
    const response = await fetchWithAuth(user, `https://keter.cloud/api/v1/nutrificient/equipment/${equipment}`, { method: 'GET' });
    const image = await fetch(response.image.url).then((res) => res.blob());
    const data = await fetch(response.data.url).then((res) => res.json()) as IngredientData
    return { image, data };
}

export async function generateEquipment(user: User | null, equipment: string) {
    const response = await fetchWithAuth(user, `https://keter.cloud/api/v1/nutrificient/equipment/new/${equipment}`, { method: 'GET' });
    return response;
}

// Recipes

export async function fetchRecipesList(user: User | null) {
    const response = await fetchWithAuth(user, 'https://keter.cloud/api/v1/nutrificient/recipes', { method: 'GET' });
    return response as string[];
}

export async function fetchRecipeDetails(user: User | null, recipe: string) {
    const response = await fetchWithAuth(user, `https://keter.cloud/api/v1/nutrificient/recipe/${recipe}`, { method: 'GET' });
    const image = await fetch(response.image.url).then((res) => res.blob());
    const data = await fetch(response.data.url).then((res) => res.json()) as IngredientData
    return { image, data };
}

export async function generateRecipe(user: User | null, freeText: string) {
    // add freeText to the request body as 'text'
    const response = await fetchWithAuth(user, `https://keter.cloud/api/v1/nutrificient/recipe/new`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: freeText }),
        });
    return response;
}