import React from 'react';
import styled from 'styled-components';
import { nutrientDisplayNames, nutrientUnits, IngredientData } from '../model/ingredient-model';

interface IngredientDetailsProps {
    ingredientData: IngredientData | null;
    ingredientImage: string;
}

export function IngredientDetails({ ingredientData, ingredientImage }: IngredientDetailsProps) {
    if (!ingredientData) {
        return <p>Select an ingredient to see details</p>;
    }

    return (
        <ContentCard>
            {/* First Row: Image and Basic Information */}
            <FirstRowWrapper>
                <HorizontalWrapper>
                    <ImagePlaceholder src={ingredientImage} />
                    <VerticalWrapper>
                        <Header>{ingredientData.name.en}</Header>
                        <Category>{ingredientData.category}</Category>
                        <div>Expiration: {ingredientData.expirationDays} days</div>
                        <div>Cost Per Unit: {ingredientData.costPerUnit}</div>
                        <div>Energy: {ingredientData.nutrition.macronutrients.calories_kcal} kcal</div>
                        <div>Possible Units: {ingredientData.possibleUnits.join(', ')}</div>
                        <div>Conversion Factors: {ingredientData.conversionFactors.join(', ')}</div>
                    </VerticalWrapper>
                </HorizontalWrapper>
            </FirstRowWrapper>

            {/* Second Row: Nutrient Categories */}
            <SecondRowWrapper>
                <NutrientRow>
                    {/* Macronutrients */}
                    <CategoryColumn>
                        <CategoryTitle>Macronutrients</CategoryTitle>
                        <ul>
                            <li><strong>Carbohydrates:</strong> {ingredientData?.nutrition.macronutrients.carbohydrates_g} g</li>
                            <li><strong>⠀Fibers⠀⠀ Soluble:</strong> {ingredientData?.nutrition.macronutrients.fiber.solubleFiber_g} g</li>
                            <li><strong>⠀⠀⠀⠀⠀⠀⠀ Insoluble:</strong> {ingredientData?.nutrition.macronutrients.fiber.insolubleFiber_g} g</li>
                            <li><strong>⠀Sugars⠀⠀ Glucose:</strong> {ingredientData?.nutrition.macronutrients.sugars.glucose_g} g</li>
                            <li><strong>⠀⠀⠀⠀⠀⠀⠀ Fructose:</strong> {ingredientData?.nutrition.macronutrients.sugars.fructose_g} g</li>
                            <li><strong>⠀⠀⠀⠀⠀⠀⠀ Sucrose:</strong> {ingredientData?.nutrition.macronutrients.sugars.sucrose_g} g</li>
                            <li><strong>⠀⠀⠀⠀⠀⠀⠀ Lactose:</strong> {ingredientData?.nutrition.macronutrients.sugars.lactose_g} g</li>
                            <li><strong>⠀⠀⠀⠀⠀⠀⠀ Added:</strong> {ingredientData?.nutrition.macronutrients.sugars.addedSugars_g} g</li>
                            <li><strong>⠀⠀⠀⠀⠀⠀⠀ Alcohols:</strong> {ingredientData?.nutrition.macronutrients.sugars.sugarAlcohols_g} g</li>
                        </ul>
                        <ul>
                            <li><strong>Fats⠀⠀Saturated:</strong> {ingredientData?.nutrition.macronutrients.fat.saturatedFat_g} g</li>
                            <li><strong>⠀⠀⠀⠀ Monounsaturated:</strong> {ingredientData?.nutrition.macronutrients.fat.monounsaturatedFat_g} g</li>
                            <li><strong>⠀⠀⠀⠀ Polyunsaturated:</strong> {ingredientData?.nutrition.macronutrients.fat.polyunsaturatedFat_g} g</li>
                            <li><strong>⠀⠀⠀⠀ Trans:</strong> {ingredientData?.nutrition.macronutrients.fat.transFat_g} g</li>
                            <li><strong>Cholesterol:</strong> {ingredientData?.nutrition.macronutrients.cholesterol_mg} mg</li>
                        </ul>
                        <ul>
                            <li><strong>Protein:</strong>{ingredientData?.nutrition.macronutrients.protein_g} g</li>
                            <li><strong>Water:</strong>{ingredientData?.nutrition.macronutrients.water_g} g</li>
                        </ul>
                    </CategoryColumn>

                    {/* Vitamins */}
                    <CategoryColumn>
                        <CategoryTitle>Vitamins</CategoryTitle>
                        <ul>
                            {Object.entries(ingredientData.nutrition.vitamins ?? {}).map(([key, value]) => (
                                <li key={key}>
                                    <strong>{nutrientDisplayNames[key] || key}:</strong> {value} {nutrientUnits[key] || ""}
                                </li>
                            ))}
                        </ul>
                    </CategoryColumn>

                    {/* Minerals */}
                    <CategoryColumn>
                        <CategoryTitle>Minerals</CategoryTitle>
                        <ul>
                            {Object.entries(ingredientData.nutrition.minerals ?? {}).map(([key, value]) => (
                                <li key={key}>
                                    <strong>{nutrientDisplayNames[key] || key}:</strong> {value} {nutrientUnits[key] || ""}
                                </li>
                            ))}
                        </ul>
                    </CategoryColumn>
                </NutrientRow>

                {/* Fatty Acids, Amino Acids, and Other Nutrients */}
                <NutrientRow>
                    {/* Fatty Acids */}
                    <CategoryColumn>
                        <CategoryTitle>Fatty Acids</CategoryTitle>
                        <ul>
                            {Object.entries(ingredientData.nutrition.fattyAcids ?? {}).map(([key, value]) => (
                                <li key={key}>
                                    <strong>{nutrientDisplayNames[key] || key}:</strong> {value} {nutrientUnits[key] || ""}
                                </li>
                            ))}
                        </ul>
                    </CategoryColumn>

                    {/* Amino Acids */}
                    <CategoryColumn>
                        <CategoryTitle>Amino Acids</CategoryTitle>
                        <ul>
                            {Object.entries(ingredientData.nutrition.aminoAcids ?? {}).map(([key, value]) => (
                                <li key={key}>
                                    <strong>{nutrientDisplayNames[key] || key}:</strong> {value} {nutrientUnits[key] || ""}
                                </li>
                            ))}
                        </ul>
                    </CategoryColumn>

                    {/* Other Nutrients */}
                    <CategoryColumn>
                        <CategoryTitle>Other Nutrients</CategoryTitle>
                        <ul>
                            {Object.entries(ingredientData.nutrition.otherNutrients ?? {}).map(([key, value]) => (
                                <li key={key}>
                                    <strong>{nutrientDisplayNames[key] || key}:</strong> {value} {nutrientUnits[key] || ""}
                                </li>
                            ))}
                        </ul>
                    </CategoryColumn>
                </NutrientRow>
            </SecondRowWrapper>
        </ContentCard>
    );
}

// Styled components

const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
`;

const FirstRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
`;

const HorizontalWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: flex-start;
`;

const VerticalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const Header = styled.div`
    font-size: 22px;
    margin-bottom: 10px;
`;

const Category = styled.div`
    font-size: 16px;
    margin-bottom: 10px;
`;

const ImagePlaceholder = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 16px;
    object-fit: cover;
`;

const SecondRowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #f6f6f6;
    border-radius: 0 0 30px 30px;
    padding: 20px;
`;

const NutrientRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
`;

const CategoryColumn = styled.div`
  flex: 1 1 200px;
  min-width: 314.5px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-top: 6px solid #e0e0e0;

    li {
      // border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      // margin-bottom: 5px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;

      strong {
        font-weight: bold;
        margin-right: auto; /* Keeps the name on the left */
      }

      .value {
        text-align: right;
      }
    }
  }
`;

const CategoryTitle = styled.h4`
    font-size: 16px;
    font-weight: bold;
    color: #fa6e67;
    margin: 0;
    padding: 0;
`;
