import styled from 'styled-components';

export enum PageBackground {
    Orange = '#fa6e67',
    Purple = '#9b6afa',
    Green = '#5ce25e',
    Blue = '#6faefa',
}

export const Page = styled.div<{ background: PageBackground }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: ${({ background }) => background};
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 1024px;
  width: 80vw;
`;

export const Title = styled.h1`
  padding-top: 10px;
  margin-bottom: 30px;
  font-size: 42px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  font-family: 'Vegan Style';
`;